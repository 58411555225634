div.mbox-background {
    background-color: grey;

    min-height: 100%;
    /* min-width: 1024px; */

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

div.mbox-background > div.messagebox {
    display: block;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    padding: 10px;
    margin-top: 10%
}

body {
    padding: 20px;
}